import { MenuItem, Stack, Typography, type SelectProps } from "@mui/material";
import type { OrganizationUser } from "@trainwell/types";
import Select from "./index";

export function RoleSelect({
  value = "member",
  ...rest
}: SelectProps<OrganizationUser["auth"]["role"]>) {
  return (
    <Select value={value} label={"Role"} {...rest}>
      <MenuItem value={"member"}>
        <Stack>
          <Typography>Member</Typography>
          <Typography variant="caption" sx={{ color: "gray.500" }}>
            Restricted permissions, having access to only assigned locations.
          </Typography>
        </Stack>
      </MenuItem>
      <MenuItem value={"admin"}>
        <Stack>
          <Typography>Administrator</Typography>
          <Typography variant="caption" sx={{ color: "gray.500" }}>
            No restrictions.
          </Typography>
        </Stack>
      </MenuItem>
    </Select>
  );
}
