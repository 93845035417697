import type { OrganizationUser } from "@trainwell/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EmailValidator } from "src/components/Shared/Fields/Text/Email";
import { NameValidator } from "src/components/Shared/Fields/Text/Name";

export default function useUserForm(user?: OrganizationUser) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [role, setRole] = useState<OrganizationUser["auth"]["role"]>("member");

  const hasChanges: () => boolean = useCallback(() => {
    const changes = [
      firstName !== user?.first_name,
      lastName !== user?.last_name,
      email !== user?.email,
      locationIds !== user?.locations,
      role !== user?.auth.role,
    ].includes(true);
    return changes;
  }, [firstName, lastName, email, locationIds, role, user]);

  const isFormValid = useMemo(() => {
    const isInvalid = [
      NameValidator(firstName),
      NameValidator(lastName),
      EmailValidator(email),
      hasChanges(),
    ].includes(false);
    return !isInvalid;
  }, [firstName, lastName, email, hasChanges]);

  const resetForm = useCallback(() => {
    setFirstName(user?.first_name ?? "");
    setLastName(user?.last_name ?? "");
    setEmail(user?.email ?? "");
    setLocationIds(user?.locations ?? []);
    setRole(user?.auth.role ?? "member");
  }, [user]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    locationIds,
    setLocationIds,
    role,
    setRole,
    isFormValid,
    resetForm,
  };
}
