import type { OrganizationLocation } from "@trainwell/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NameValidator } from "src/components/Shared/Fields/Text/Name";

export default function useLocationForm(location?: OrganizationLocation) {
  const [name, setName] = useState(location?.name ?? "");

  const hasChanges: () => boolean = useCallback(() => {
    const changes = [name !== location?.name].includes(true);
    return changes;
  }, [name, location]);

  const isFormValid = useMemo(() => {
    const isInvalid = [NameValidator(name), hasChanges()].includes(false);
    return !isInvalid;
  }, [name, hasChanges]);

  const resetForm = useCallback(() => {
    setName(location?.name ?? "");
  }, [location]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return {
    name,
    setName,
    isFormValid,
    resetForm,
  };
}
