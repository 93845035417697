import {
  Dialog,
  DialogContent,
  DialogTitle,
  type DialogContentProps,
  type DialogProps,
} from "@mui/material";

export type ModalProps = {
  contentProps?: DialogContentProps;
} & DialogProps;

export function Modal({
  children,
  title,
  open,
  onClose,
  contentProps: { sx, ...restContentProps } = {},
  ...rest
}: ModalProps) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" {...rest}>
      <DialogContent
        sx={{ p: "32px 24px 32px 24px", ...sx }}
        {...restContentProps}
      >
        {title && (
          <DialogTitle
            sx={{
              p: "0px 0px 10px 0px",
              borderBottom: "1px solid #EAECF0",
            }}
          >
            {title}
          </DialogTitle>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
}
