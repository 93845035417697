import { createApi } from "@trainwell/api-sdk";
import { getAuth } from "firebase/auth";
import {
  TRAINWELL_API_DOMAIN,
  TRAINWELL_API_KEY,
  TRAINWELL_PYTHON_API_DOMAIN,
} from "src/config/config";

export const api = createApi({
  apiUrl: TRAINWELL_API_DOMAIN,
  pythonApiUrl: TRAINWELL_PYTHON_API_DOMAIN,
  apiKey: TRAINWELL_API_KEY,
  getTokenString: () => {
    const auth = getAuth();
    return auth.currentUser?.getIdToken();
  },
});
