import type { OrganizationLocation } from "@trainwell/types";
import { useState, type FormEventHandler } from "react";
import { NameTextField } from "src/components/Shared/Fields/Text/Name";
import { ConfirmDeleteModal } from "src/components/Shared/Modals/ConfirmDeleteModal";
import { FormModal } from "src/components/Shared/Modals/Form";
import FormFields from "src/components/Shared/Modals/Form/Fields";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  deleteLocation,
  resetDeleteLocationState,
  resetUpdateLocationState,
  updateLocation,
} from "src/redux/slices/locationsSlice";
import DeleteIconButton from "../../Shared/Buttons/DeleteIconButton";
import useLocationForm from "./useLocationForm";

export default function ManageLocation({
  location,
  onClose,
}: {
  location?: OrganizationLocation;
  onClose: () => void;
}) {
  const updateLocationState = useAppSelector(
    (s) => s.locations.updateLocationState,
  );
  const deleteLocationState = useAppSelector(
    (s) => s.locations.deleteLocationState,
  );
  const dispatch = useAppDispatch();

  const { name, setName, isFormValid } = useLocationForm(location);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!location) return;
    dispatch(updateLocation({ locationId: location._id, updates: { name } }));
  };

  const handleDeleteLocation: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!location) return;
    dispatch(deleteLocation({ locationId: location._id }));
  };

  const handleClose = () => {
    onClose();
    dispatch(resetUpdateLocationState());
    dispatch(resetDeleteLocationState());
  };

  const handleCloseFromDeletion = () => {
    setIsConfirmingDelete(false);
    handleClose();
  };

  return (
    <FormModal
      open={!!location}
      title="Manage Location"
      ctaTitle="Edit"
      successMessage="Location edited successfully."
      isFormValid={isFormValid}
      networkingState={updateLocationState}
      leftActions={
        <DeleteIconButton onClick={() => setIsConfirmingDelete(true)} />
      }
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <FormFields networkingState={updateLocationState}>
        <NameTextField
          id="name"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          disabled={updateLocationState.status === "loading"}
        />
      </FormFields>
      <ConfirmDeleteModal
        open={isConfirmingDelete}
        title={`Delete ${location?.name ?? "Location"}?`}
        successMessage={`${location?.name ?? "Location"} deleted.`}
        networkingState={deleteLocationState}
        onSubmit={handleDeleteLocation}
        onClose={handleCloseFromDeletion}
      />
    </FormModal>
  );
}
