import { type TableCellProps } from "@mui/material";

const COMMON_CELL_PROPS: TableCellProps = {
  align: "left",
  sx: {
    p: "12px 24px",
  },
};

export const DEFAULT_BORDER = "1px solid #EAECF0";

export const DEFAULT_HEADER_CELL_PROPS: TableCellProps = {
  ...COMMON_CELL_PROPS,
  sx: {
    ...COMMON_CELL_PROPS.sx,
    borderBottom: DEFAULT_BORDER,
    color: "gray.500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export const DEFAULT_BODY_CELL_PROPS: TableCellProps = {
  ...COMMON_CELL_PROPS,
  sx: {
    ...COMMON_CELL_PROPS.sx,
    borderTop: DEFAULT_BORDER,
    color: "gray.900",
  },
};
