import { TableCell, TableRow } from "@mui/material";
import { DEFAULT_BODY_CELL_PROPS } from "../defaults";
import {
  type SucceededTableState,
  type TableColumn,
  type TableDelegateMethods,
} from "../types";

export default function SucceededBodyContent<Item, Column extends TableColumn>({
  columns,
  data,
  keyForRow,
  renderCell,
  onClickRow,
  onClickCell,
}: {
  columns: Column[];
} & SucceededTableState<Item> &
  TableDelegateMethods<Item, Column>) {
  return (
    <>
      {data.map((item, i) => {
        const rowKey = keyForRow(item);
        const handleClickRow = () => {
          if (onClickRow) {
            onClickRow(item);
          }
        };
        return (
          <TableRow
            key={rowKey}
            onClick={handleClickRow}
            sx={{
              bgcolor: i % 2 === 0 ? "gray.50" : "white",
              height: "72px",
              cursor: onClickRow ? "pointer" : undefined,
            }}
          >
            {columns.map((c) => {
              const handleClickCell = () => {
                if (onClickCell) {
                  onClickCell(item, c);
                }
              };
              return (
                <TableCell
                  key={`${rowKey}.${c.key}`}
                  onClick={handleClickCell}
                  {...DEFAULT_BODY_CELL_PROPS}
                >
                  {renderCell(item, c)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </>
  );
}
