import { useState } from "react";
import { TablePageContainer } from "../Shared/Table/PageContainer";
import { ClientsTable } from "./ClientsTable";
import InviteClientModal from "./Modals/InviteClient";

export function Clients() {
  const [isCreating, setIsCreating] = useState(false);

  const handleStartCreating = () => {
    setIsCreating(true);
  };

  const handleStopCreating = () => {
    setIsCreating(false);
  };

  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px" }}>
      <ClientsTable onCreate={handleStartCreating} />
      <InviteClientModal
        key={isCreating.toString()}
        open={isCreating}
        onClose={handleStopCreating}
      />
    </TablePageContainer>
  );
}
