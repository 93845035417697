import { TableCell, TableHead, TableRow } from "@mui/material";
import { DEFAULT_HEADER_CELL_PROPS } from "./defaults";
import { type TableColumn } from "./types";

export default function TableHeader({ columns }: { columns: TableColumn[] }) {
  return (
    <TableHead>
      <TableRow sx={{ height: "44px" }}>
        {columns.map((c) => (
          <TableCell key={c.key} {...{ ...DEFAULT_HEADER_CELL_PROPS }}>
            {c.title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
