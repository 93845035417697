const HOME = {
  title: "Home",
  iconPath: "/assets/home.svg",
  path: () => "/",
};

const SIGN_IN = {
  path: () => "/sign-in",
  finish: {
    path: () => "/sign-in/finish",
  },
};

const CLIENTS = {
  title: "Patients",
  description: "View and manage patients across locations.",
  iconPath: "/assets/patient.svg",
  path: () => "/patients",
  detail: {
    path: (clientId = ":clientId", print = false) =>
      `/patients/${clientId}${print ? "/print" : ""}`,
  },
};

const USERS = {
  title: "Users",
  description: "View and manage authorized users in your organization",
  iconPath: "/assets/user.svg",
  path: () => "/users",
};

const LOCATIONS = {
  title: "Locations",
  description: "View and manage locations in your organization",
  iconPath: "/assets/location.svg",
  path: () => "/locations",
};

export const APP_ROUTES = {
  HOME,
  SIGN_IN,
  CLIENTS,
  USERS,
  LOCATIONS,
};
