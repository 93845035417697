import {
  type SelectProps,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

export default function CustomSelect<T>({
  children,
  id,
  label,
  ...rest
}: SelectProps<T>) {
  return (
    <FormControl sx={{ mt: "8px", mb: "4px" }}>
      <InputLabel id={id} size="small">
        {label}
      </InputLabel>
      <Select<T> labelId={id} id={id} label={label} size="small" {...rest}>
        {children}
      </Select>
    </FormControl>
  );
}
