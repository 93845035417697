import { Link, Stack } from "@mui/material";
import React from "react";
import { type Breadcrumb } from "src/hooks/useBreadcrumbs";

export default function Breadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: Breadcrumb[];
}) {
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        alignItems: "center",
        gap: "16px",
      }}
    >
      {breadcrumbs.map((breadcrumb, i) => (
        <React.Fragment key={breadcrumb.path}>
          <Link
            href={breadcrumb.path}
            sx={{
              textDecoration: "none",
              color: "gray.500",
              fontSize: "14px",
              lineHeight: "100%",
              ":hover": {
                color: "gray.700",
              },
            }}
          >
            {breadcrumb.title}
          </Link>
          {i < breadcrumbs.length - 1 && (
            <svg
              width="6"
              height="11"
              viewBox="0 0 6 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.97461 5.76953C5.97461 5.84375 5.95898 5.91406 5.92773 5.98047C5.90039 6.04297 5.85938 6.10156 5.80469 6.15625L1.16406 10.6973C1.05859 10.8027 0.929688 10.8555 0.777344 10.8555C0.679688 10.8555 0.589844 10.832 0.507812 10.7852C0.425781 10.7383 0.361328 10.6738 0.314453 10.5918C0.267578 10.5137 0.244141 10.4238 0.244141 10.3223C0.244141 10.1777 0.294922 10.0508 0.396484 9.94141L4.66211 5.76953L0.396484 1.59766C0.294922 1.48828 0.244141 1.36133 0.244141 1.2168C0.244141 1.11523 0.267578 1.02539 0.314453 0.947266C0.361328 0.865234 0.425781 0.800781 0.507812 0.753906C0.589844 0.707031 0.679688 0.683594 0.777344 0.683594C0.929688 0.683594 1.05859 0.734375 1.16406 0.835938L5.80469 5.38281C5.85938 5.4375 5.90039 5.49805 5.92773 5.56445C5.95898 5.62695 5.97461 5.69531 5.97461 5.76953Z"
                fill="#443564"
                fillOpacity="0.66"
              />
            </svg>
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
}
