import { Stack, type StackProps } from "@mui/material";
import { NetworkingState } from "src/types/NetworkingState";

export default function FormFields<T>({
  children,
  networkingState,
  sx,
  ...rest
}: StackProps & { networkingState: NetworkingState<T> }) {
  if (networkingState.status === "succeeded") return null;
  return (
    <Stack direction="column" gap="8px" sx={{ ...sx }} {...rest}>
      {children}
    </Stack>
  );
}
