import { useMemo } from "react";
import { APP_ROUTES } from "src/constants/AppRoutes";
import { useAuth } from "src/providers/AuthProvider";

export default function usePrimaryRoutesForRole() {
  const { role, isAuthenticated } = useAuth();

  const [memberRoutes, adminRoutes] = useMemo(() => {
    const memberRoutes = [APP_ROUTES.CLIENTS];
    const adminRoutes = [
      ...memberRoutes,
      APP_ROUTES.USERS,
      APP_ROUTES.LOCATIONS,
    ];
    return [memberRoutes, adminRoutes];
  }, []);

  if (!isAuthenticated) return [];

  return role === "admin" ? adminRoutes : memberRoutes;
}
