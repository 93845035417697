import { type TextFieldProps } from "@mui/material";
import { Validator } from "src/lib/validators";
import TextField from "./index";

export const { validator: EmailValidator, message: EmailHelperMessage } =
  Validator.String.email;

export function EmailTextField({ value, ...rest }: TextFieldProps) {
  return (
    <TextField
      value={value}
      helperText={!EmailValidator(value as string) ? EmailHelperMessage : null}
      {...rest}
    />
  );
}
