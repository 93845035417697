import { Stack, Typography, type StackProps } from "@mui/material";
import { DEFAULT_BORDER } from "../defaults";
import type { GlobalHeaderConfig } from "../types";

export default function GlobalTableHeader({
  title,
  subtitle,
  rightChildren,
  sx,
  ...rest
}: StackProps & GlobalHeaderConfig) {
  if (!title && !subtitle) return null;
  return (
    <Stack
      direction="row"
      sx={{
        p: "20px",
        gap: "16px",
        justifyContent: "space-between",
        borderBottom: DEFAULT_BORDER,
        ...sx,
      }}
      {...rest}
    >
      <Stack direction="column" sx={{ gap: "4px" }}>
        {title && (
          <Typography
            sx={{
              fontSize: { xs: "18px" },
              fontWeight: 700,
              color: "gray.900",
            }}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            sx={{
              fontSize: { xs: "14px" },
              fontWeight: 300,
              color: "gray.600",
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
      {rightChildren}
    </Stack>
  );
}
