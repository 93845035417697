import {
  createTheme,
  responsiveFontSizes,
  type Theme,
} from "@mui/material/styles";
import CircularStdBold from "src/assets/fonts/circular-std-bold.woff2";
import CircularStdBook from "src/assets/fonts/circular-std-book.woff2";
import CircularStdLight from "src/assets/fonts/circular-std-light.woff2";
import { LinkBehavior } from "src/components/misc/LinkBehavior";

const plainTheme = createTheme();

type Brightness =
  | "50"
  | "100"
  | "200"
  | "300"
  | "400"
  | "500"
  | "600"
  | "700"
  | "800"
  | "900";

// Create a theme instance.
export default function getTheme(themeMode: string): Theme {
  // Hardcoded until dark mode looks good
  themeMode = "light";

  const darkBlue = "#443564";

  return responsiveFontSizes(
    createTheme({
      palette: {
        mode: themeMode === "dark" ? "dark" : "light",
        background: {
          default: "#ffffff",
        },
        brand: {
          "50": "#F9F5FF",
          "100": "#F4EBFF",
          "200": "#E9D7FE",
          "300": "#D6BBFB",
          "400": "#B692F6",
          "500": "#9E77ED",
          "600": "#7F56D9",
          "700": "#6941C6",
          "800": "#53389E",
          "900": "#42307D",
        },
        gray: {
          "50": "#F9FAFB",
          "100": "#F2F4F7",
          "200": "#EAECF0",
          "300": "#D0D5DD",
          "500": "#667085",
          "600": "#475467",
          "700": "#344054",
          "800": "#182230",
          "900": "#101828",
        },
        primary: {
          main: darkBlue,
        },
        secondary: {
          main: "#88cfff",
        },
        success: {
          main: "#379764",
          50: "#ECFDF3",
          200: "#ABEFC6",
          500: "#17B26A",
          700: "#067647",
        },
        error: {
          main: "#EC4835",
          50: "#FEF2F1",
          200: "#F7ADA6",
          500: "#E41D07",
          700: "#A31505",
        },
        text: {
          primary: "#252525",
        },
        divider: "#E9E9E9",
        backgroundSecondary: {
          main: "#EEE",
        },
        backgroundTertiary: {
          main: "#f7f7f7",
        },
      },
      typography: {
        fontFamily: "'CircularStd', sans-serif",
        h1: {
          fontSize: 32,
          fontWeight: "bold",
        },
        h2: {
          fontSize: 26,
          fontWeight: "bold",
        },
        h3: {
          fontSize: 22,
          fontWeight: "bold",
        },
        h4: {
          fontSize: 19,
          fontWeight: 700,
        },
        subtitle1: {
          fontSize: 18,
          lineHeight: 1.5,
        },
        subtitle2: {
          fontSize: 17,
          textTransform: "uppercase",
        },
        body1: {
          fontSize: 18,
        },
        body2: {
          fontSize: 16,
        },
        overline: {
          lineHeight: 1.5,
        },
      },
      shape: {
        borderRadius: 8,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
          @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 300;
            src: local('CircularStd'), local('CircularStd-Light'), url(${CircularStdLight}) format('woff2');
          }

          @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('CircularStd'), local('CircularStd-Book'), url(${CircularStdBook}) format('woff2');
          }

          @font-face {
            font-family: 'CircularStd';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: local('CircularStd'), local('CircularStd-Bold'), url(${CircularStdBold}) format('woff2');
          }
          `,
        },
        MuiCard: {
          defaultProps: {
            variant: "outlined",
          },
        },
        MuiButton: {
          defaultProps: {
            LinkComponent: LinkBehavior,
            variant: "contained",
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              fontWeight: "bold",
              textTransform: "none",
              fontSize: 18,
            },
            sizeSmall: {
              fontSize: 14,
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              fontSize: 16,
              borderRadius: 8,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderWidth: "2px",
              borderColor: "#DEDEDE",
            },
          },
        },
        MuiDialogTitle: {
          defaultProps: {
            variant: "h2",
          },
        },
        MuiDialogContentText: {
          defaultProps: {
            variant: "body1",
            color: "text.primary",
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              [plainTheme.breakpoints.down("sm")]: {
                margin: "8px",
                width: "calc(100% - 16px)",
                maxHeight: "calc(100% - 16px)",
              },
            },
          },
        },
        MuiListItemButton: {
          defaultProps: {
            LinkComponent: LinkBehavior,
          },
        },
        MuiLink: {
          defaultProps: {
            component: LinkBehavior,
            sx: {
              textDecoration: "none",
              color: (theme) => theme.palette.brand[700],
              ":hover": {
                color: (theme) => theme.palette.brand[500],
              },
            },
          },
        },
      },
    }),
  );
}

declare module "@mui/material/styles" {
  interface Palette {
    backgroundSecondary: Palette["primary"];
    backgroundTertiary: Palette["primary"];
    gray: Record<Brightness, string>;
    brand: Record<Brightness, string>;
  }

  interface PaletteOptions {
    backgroundSecondary?: PaletteOptions["primary"];
    backgroundTertiary?: PaletteOptions["primary"];
    gray?: PaletteOptions["primary"];
    brand?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    selected: true;
  }
}
//
