import { Avatar, Stack, Typography } from "@mui/material";
import { type OrgClientSummary } from "@trainwell/types";
import { getFormattedDateString } from "../../lib/utils";

export const ClientNameCellContent = ({
  summary,
}: {
  summary: OrgClientSummary;
}) => (
  <Stack direction="row" sx={{ gap: "12px" }}>
    <Avatar src={summary.headshot_url} />
    <Stack direction="column" sx={{ gap: "0px" }}>
      <Typography
        sx={{
          fontSize: { xs: "14px" },
          fontWeight: 500,
          color: "gray.900",
          whiteSpace: "nowrap",
        }}
      >
        {`${summary.first_name} ${summary.last_name}`}
      </Typography>

      <Typography
        sx={{
          fontSize: { xs: "14px" },
          fontWeight: 300,
          color: "gray.600",
          whiteSpace: "nowrap",
        }}
      >
        {`Joined ${getFormattedDateString(summary.date_membership_started)}`}
      </Typography>
    </Stack>
  </Stack>
);
