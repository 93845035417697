import { Stack, type StackProps } from "@mui/material";

export default function FormActions({ children, sx, ...rest }: StackProps) {
  return (
    <Stack
      direction="row"
      gap="16px"
      sx={{
        width: "100%",
        height: "40px",
        "& > *": { height: "100%" },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
}
