import { Button, Stack, type StackProps } from "@mui/material";
import { useMemo } from "react";
import { DEFAULT_BORDER } from "../defaults";
import type { GlobalFooterConfig, TableState } from "../types";

const getPageNumbers = (currentPage: number, totalPages: number) => {
  if (totalPages <= 1) return [];

  if (totalPages <= 5) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  let start = Math.max(1, currentPage - 2);
  const end = Math.min(totalPages, start + 4);

  if (end - start < 4) {
    start = Math.max(1, end - 4);
  }

  return Array.from({ length: 5 }, (_, i) => start + i);
};

export default function GlobalTableFooter<T>({
  state,
  onSelectPage,
  sx,
  ...rest
}: StackProps & {
  state: TableState<T>;
} & GlobalFooterConfig) {
  const currentPage = useMemo(
    () => (state.status === "succeeded" ? (state.currentPage ?? 0) : 0),
    [state],
  );
  const totalPages = useMemo(
    () => (state.status === "succeeded" ? (state.totalPages ?? 0) : 0),
    [state],
  );
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: "center",
        p: "20px",
        gap: "4px",
        minHeight: "80px",
        borderTop: DEFAULT_BORDER,
        ...sx,
      }}
      {...rest}
    >
      <Stack direction="row" sx={{ gap: "4px" }}>
        {getPageNumbers(currentPage, totalPages).map((page) => (
          <Button
            key={page}
            variant={"selected"}
            sx={{
              width: "40px",
              minWidth: "unset",
              height: "40px",
              bgcolor: currentPage === page ? "gray.50" : "white",
              color: currentPage === page ? "gray.800" : "gray.600",
              fontWeight: "500",
              p: "0px",
              "& .MuiButton-label": {
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            onClick={onSelectPage ? () => onSelectPage(page) : undefined}
          >
            {page}
          </Button>
        ))}
      </Stack>
    </Stack>
  );
}
