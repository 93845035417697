import { Stack, type StackProps } from "@mui/material";
import type { ReactNode } from "react";

const ChipStyle = {
  neutral: {
    backgroundColor: "gray.50",
    foregroundColor: "gray.700",
    borderColor: "#EAECF0",
  },
  success: {
    backgroundColor: "success.50",
    foregroundColor: "success.700",
    borderColor: "#ABEFC6",
  },
  primary: {
    backgroundColor: "brand.50",
    foregroundColor: "brand.700",
    borderColor: "#E9D7FE",
  },
};

export const Chip = ({
  chipStyle = "neutral",
  children,
  sx,
  ...rest
}: { children: ReactNode } & StackProps & {
    chipStyle: keyof typeof ChipStyle;
  }) => (
  <Stack
    direction="row"
    sx={{
      gap: "4px",
      p: "2px 8px",
      justifyContent: "flex-start",
      alignItems: "center",
      bgcolor: ChipStyle[chipStyle].backgroundColor,
      color: ChipStyle[chipStyle].foregroundColor,
      fill: "currentColor",
      border: `1px solid ${ChipStyle[chipStyle].borderColor}`,
      borderRadius: "32px",
      width: "fit-content",
      "& svg": {
        fill: "currentColor",
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Stack>
);
