import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type {
  B2bBillingUserCreate,
  OrgClientDetails,
  OrgClientSummary,
  PaginatedData,
} from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/redux/stores/store";
import { NetworkingState } from "src/types/NetworkingState";

export const getClientSummaries = createAsyncThunk(
  "clients/getClientSummaries",
  async (
    { page = 1, locationId }: { page?: number; locationId?: string },
    { getState },
  ) => {
    const { locations: locationsState } = getState() as RootState;
    const result = await api.organizations.getClientSummaries(
      page,
      locationId ??
        (locationsState.selectedLocation.type === "location"
          ? locationsState.selectedLocation.location._id
          : undefined),
    );
    return result;
  },
);

export const getClientSummary = createAsyncThunk(
  "clients/getClientSummary",
  async (clientId: string) => {
    const { clientSummary } =
      await api.organizations.getClientSummary(clientId);
    return clientSummary;
  },
);

export const getClientDetails = createAsyncThunk(
  "clients/getClientDetails",
  async (clientId: string) => {
    const { clientDetails } =
      await api.organizations.getClientDetails(clientId);
    return clientDetails;
  },
);

export const inviteClient = createAsyncThunk(
  "clients/inviteClient",
  async (createBillingUser: B2bBillingUserCreate) => {
    await api.organizations.inviteClient(createBillingUser);
  },
);

interface ClientsState {
  clientSummariesState: NetworkingState<PaginatedData<OrgClientSummary>>;
  clientStatesById: Record<
    string,
    | {
        summaryState: NetworkingState<OrgClientSummary>;
        detailsState?: NetworkingState<OrgClientDetails>;
      }
    | undefined
  >;
  inviteClientState: NetworkingState<void>;
}

// Define the initial state using that type
const initialState: ClientsState = {
  clientSummariesState: NetworkingState.idle(),
  clientStatesById: {},
  inviteClientState: NetworkingState.idle(),
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    resetClients: () => initialState,
    resetInviteClientState: (state) => {
      state.inviteClientState = initialState.inviteClientState;
    },
  },
  extraReducers: (builder) => {
    // GET CLIENTS
    builder.addCase(getClientSummaries.pending, (state) => {
      state.clientSummariesState = NetworkingState.loading();
    });
    builder.addCase(getClientSummaries.fulfilled, (state, action) => {
      state.clientSummariesState = NetworkingState.succeeded(action.payload);
      state.clientStatesById = action.payload.data.reduce(
        (acc: ClientsState["clientStatesById"], summary) => {
          if (summary.user_id) {
            acc[summary.user_id] = {
              summaryState: NetworkingState.succeeded(summary),
            };
          }
          return acc;
        },
        state.clientStatesById,
      );
    });
    builder.addCase(getClientSummaries.rejected, (state, action) => {
      state.clientSummariesState = NetworkingState.failed(action.error.message);
    });

    // GET CLIENT SUMMARY BY ID
    builder.addCase(getClientSummary.pending, (state, action) => {
      const clientId = action.meta.arg;
      state.clientStatesById[clientId] = {
        summaryState: NetworkingState.loading(),
      };
    });
    builder.addCase(getClientSummary.fulfilled, (state, action) => {
      const clientId = action.meta.arg;
      state.clientStatesById[clientId] = {
        summaryState: NetworkingState.succeeded(action.payload),
      };
    });
    builder.addCase(getClientSummary.rejected, (state, action) => {
      const clientId = action.meta.arg;
      state.clientStatesById[clientId] = {
        summaryState: NetworkingState.failed(action.error.message),
      };
    });

    // GET CLIENT DETAILS BY ID
    builder.addCase(getClientDetails.pending, (state, action) => {
      const clientId = action.meta.arg;
      const summaryState = state.clientStatesById[clientId]?.summaryState;
      if (summaryState) {
        state.clientStatesById[clientId] = {
          summaryState,
          detailsState: NetworkingState.loading(),
        };
      }
    });
    builder.addCase(getClientDetails.fulfilled, (state, action) => {
      const clientId = action.meta.arg;
      const summaryState = state.clientStatesById[clientId]?.summaryState;
      if (summaryState) {
        state.clientStatesById[clientId] = {
          summaryState,
          detailsState: NetworkingState.succeeded(action.payload),
        };
      }
    });
    builder.addCase(getClientDetails.rejected, (state, action) => {
      const clientId = action.meta.arg;
      const summaryState = state.clientStatesById[clientId]?.summaryState;
      if (summaryState) {
        state.clientStatesById[clientId] = {
          summaryState,
          detailsState: NetworkingState.failed(action.error.message),
        };
      }
    });

    // CREATE CLIENT
    builder.addCase(inviteClient.pending, (state) => {
      state.inviteClientState = NetworkingState.loading();
    });
    builder.addCase(inviteClient.fulfilled, (state, action) => {
      state.inviteClientState = NetworkingState.succeeded(action.payload);
    });
    builder.addCase(inviteClient.rejected, (state, action) => {
      state.inviteClientState = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetClients, resetInviteClientState } = clientsSlice.actions;

export default clientsSlice.reducer;
