import { type FormEventHandler } from "react";
import { NameTextField } from "src/components/Shared/Fields/Text/Name";
import { FormModal } from "src/components/Shared/Modals/Form";
import FormFields from "src/components/Shared/Modals/Form/Fields";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  createLocation,
  resetCreateLocationState,
} from "src/redux/slices/locationsSlice";
import useLocationForm from "./useLocationForm";

export default function CreateLocation({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const createLocationState = useAppSelector(
    (state) => state.locations.createLocationState,
  );
  const dispatch = useAppDispatch();

  const { name, setName, isFormValid } = useLocationForm();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!name) return;
    dispatch(createLocation({ name }));
  };

  const handleClose = () => {
    onClose();
    dispatch(resetCreateLocationState());
  };

  return (
    <FormModal
      open={open}
      title="Create Location"
      ctaTitle="Create"
      successMessage="Location created successfully."
      isFormValid={isFormValid}
      networkingState={createLocationState}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <FormFields networkingState={createLocationState}>
        <NameTextField
          id="name"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          disabled={createLocationState.status === "loading"}
        />
      </FormFields>
    </FormModal>
  );
}
