import { type ReactNode } from "react";

export type TableColumn = { key: string; title: string };

export type EmptyTableState = { status: "empty"; message: string };
export type LoadingTableState = { status: "loading"; message: string };
export type SucceededTableState<T> = {
  status: "succeeded";
  data: T[];
  currentPage?: number;
  totalPages?: number;
};
export type FailedTableState = {
  status: "failed";
  message: string;
  onTryAgain?: () => void;
};

export type TableState<T> =
  | EmptyTableState
  | LoadingTableState
  | SucceededTableState<T>
  | FailedTableState;

export const TableState = {
  empty(message: string): EmptyTableState {
    return { status: "empty", message };
  },

  loading(message: string): LoadingTableState {
    return { status: "loading", message };
  },

  succeeded<T>(
    data: T[],
    currentPage?: number,
    totalPages?: number,
  ): SucceededTableState<T> {
    return { status: "succeeded", data, currentPage, totalPages };
  },

  failed(message: string, onTryAgain?: () => void): FailedTableState {
    return { status: "failed", message, onTryAgain };
  },
};

export type TableDelegateMethods<Item, Column extends TableColumn> = {
  keyForRow: (item: Item) => string;
  renderCell: (item: Item, column: Column) => ReactNode;
  onClickRow?: (item: Item) => void;
  onClickCell?: (item: Item, column: Column) => void;
};

export type GlobalHeaderConfig = {
  title?: string;
  subtitle?: string;
  rightChildren?: ReactNode;
};

export type GlobalFooterConfig = {
  onSelectPage?: (page: number) => void;
};
