import { Stack, type StackProps } from "@mui/material";

export const TablePageContainer = ({ children, sx, ...rest }: StackProps) => {
  return (
    <Stack
      direction="column"
      gap="72px"
      sx={{
        mt: "24px",
        mb: "24px",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
};
