import { type NetworkingState } from "src/types/NetworkingState";

export const getPercentageString = (
  numerator?: number,
  denominator?: number,
): string => {
  if (!numerator || !denominator) return "N/A";
  return `${((numerator / denominator) * 100).toFixed(0)}%`;
};

export const getFormattedDateString = (
  dateString: Date | string | null | undefined,
): string => {
  if (!dateString) return "";
  const date =
    typeof dateString === "string" ? new Date(dateString) : dateString;
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const getCapitalizedString = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const shouldRefreshForNetworkState = <T>(
  networkingState?: NetworkingState<T>,
): boolean => {
  switch (networkingState?.status) {
    case undefined:
      // Refresh if we have no networking state.
      return true;
    case "idle":
      // Refresh if networking state is idle.
      return true;
    case "loading":
      // Started at least 30 seconds ago
      return Date.now() - networkingState.startedAt > 30 * 1_000;
    case "succeeded":
      // Fetched at least 15 mins ago
      return Date.now() - networkingState.succeededAt > 60 * 15 * 1_000;
    case "failed":
      // Failed at least 15 seconds ago
      return Date.now() - networkingState.failedAt > 15 * 1_000;
  }
};
