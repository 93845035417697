import { Box, Table, TableBody, TableContainer } from "@mui/material";
import EmptyBodyContent from "./Body/Empty";
import FailedBodyContent from "./Body/Failed";
import LoadingBodyContent from "./Body/Loading";
import SucceededBodyContent from "./Body/Succeeded";
import GlobalFooter from "./Global/Footer";
import GlobalHeader from "./Global/Header";
import TableHeader from "./Header";
import type {
  GlobalFooterConfig,
  GlobalHeaderConfig,
  TableColumn,
  TableDelegateMethods,
  TableState,
} from "./types";

export default function CustomTable<Item, Column extends TableColumn>({
  headerConfig,
  footerConfig = {},
  columns,
  tableState,
  delegateMethods,
}: {
  headerConfig: GlobalHeaderConfig;
  footerConfig?: GlobalFooterConfig;
  columns: Column[];
  tableState: TableState<Item>;
  delegateMethods: TableDelegateMethods<Item, Column>;
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <GlobalHeader {...headerConfig} />
        <TableContainer
          component={Box}
          sx={{ overflowY: "auto", height: "100%" }}
        >
          <Table stickyHeader sx={{ minWidth: "100%", minHeight: "100%" }}>
            <TableHeader columns={columns} />
            <TableBody>
              {tableState.status === "empty" && (
                <EmptyBodyContent columns={columns} {...tableState} />
              )}

              {tableState.status === "loading" && (
                <LoadingBodyContent columns={columns} {...tableState} />
              )}

              {tableState.status === "failed" && (
                <FailedBodyContent columns={columns} {...tableState} />
              )}

              {tableState.status === "succeeded" && (
                <SucceededBodyContent
                  columns={columns}
                  {...tableState}
                  {...delegateMethods}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <GlobalFooter state={tableState} {...footerConfig} />
      </Box>
    </>
  );
}
