import type { RootState } from "src/redux/stores/store";
import { TableState, type TableColumn } from "../Shared/Table/types";

export type UserTableColumn = TableColumn & {
  key: "first_name" | "last_name" | "email" | "locations" | "role";
};

export const columns: UserTableColumn[] = [
  { key: "first_name", title: "First Name" },
  { key: "last_name", title: "Last Name" },
  { key: "email", title: "Email" },
  { key: "locations", title: "Locations" },
  { key: "role", title: "Role" },
];

export function getTableState(
  state: RootState["user"]["usersState"],
  onTryAgain: () => void,
) {
  switch (state.status) {
    case "idle":
      return TableState.empty("No users found.");
    case "loading":
      return TableState.loading("Fetching users");
    case "succeeded":
      return state.data.data.length === 0
        ? TableState.empty("No users found.")
        : TableState.succeeded(
            state.data.data,
            state.data.page,
            state.data.totalPages,
          );
    case "failed":
      return TableState.failed(state.error, onTryAgain);
  }
}
