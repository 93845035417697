import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import { DEFAULT_BORDER } from "../defaults";
import { type EmptyTableState, type TableColumn } from "../types";

export default function EmptyBodyContent({
  columns,
  message,
}: { columns: TableColumn[] } & EmptyTableState) {
  return (
    <TableRow
      key={"empty.row"}
      sx={{
        height: "72px",
      }}
    >
      <TableCell
        colSpan={columns.length}
        key={"empty.cell"}
        sx={{
          bgcolor: "gray.50",
          borderTop: DEFAULT_BORDER,
          borderBottom: DEFAULT_BORDER,
        }}
      >
        <Stack
          direction="column"
          gap="10px"
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>{message}</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
