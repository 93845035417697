import { Button, Typography } from "@mui/material";
import { FormModal, type FormModalProps } from "./Form";
import FormFields from "./Form/Fields";

export function ConfirmDeleteModal<T>({
  open,
  title = "Are you sure?",
  body = "This action is permanent.",
  successMessage,
  networkingState,
  onClose,
  onSubmit,
  ...rest
}: Omit<
  FormModalProps<T>,
  "children" | "title" | "ctaTitle" | "isFormValid"
> & {
  title?: string;
  body?: string;
}) {
  return (
    <FormModal
      open={open}
      title={title}
      ctaTitle="Delete"
      successMessage={successMessage}
      isFormValid={true}
      networkingState={networkingState}
      submitButtonProps={{ color: "error" }}
      leftActions={
        <Button
          variant="outlined"
          color="primary"
          sx={{ width: "100%" }}
          onClick={onClose}
        >
          Cancel
        </Button>
      }
      onClose={onClose}
      onSubmit={onSubmit}
      {...rest}
    >
      <FormFields networkingState={networkingState}>
        <Typography sx={{ textAlign: "center" }}>{body}</Typography>
      </FormFields>
    </FormModal>
  );
}
