import { type FormEventHandler } from "react";
import { EmailTextField } from "src/components/Shared/Fields/Text/Email";
import { NameTextField } from "src/components/Shared/Fields/Text/Name";
import { PhoneTextField } from "src/components/Shared/Fields/Text/Phone";
import { FormModal } from "src/components/Shared/Modals/Form";
import FormFields from "src/components/Shared/Modals/Form/Fields";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  inviteClient,
  resetInviteClientState,
} from "src/redux/slices/clientsSlice";
import useInviteClientForm from "./useInviteClientForm";

export default function InviteClientModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const organizationId = useAppSelector((state) =>
    state.organization.organizationState.status === "succeeded"
      ? state.organization.organizationState.data._id
      : "",
  );
  const inviteClientState = useAppSelector(
    (state) => state.clients.inviteClientState,
  );
  const dispatch = useAppDispatch();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    isFormValid,
  } = useInviteClientForm();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const formattedPhone = `+1${phone.replaceAll("-", "")}`;

    dispatch(
      inviteClient({
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: formattedPhone,
        organization_id: organizationId,
      }),
    );
  };

  const handleClose = () => {
    onClose();
    dispatch(resetInviteClientState());
  };

  return (
    <FormModal
      open={open}
      title="Invite Client"
      ctaTitle="Invite"
      successMessage="Invite sent to patient! They will show up on this dashboard once they complete registration."
      isFormValid={isFormValid}
      networkingState={inviteClientState}
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <FormFields networkingState={inviteClientState}>
        <NameTextField
          autoFocus
          id="firstName"
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          disabled={inviteClientState.status === "loading"}
        />
        <NameTextField
          id="lastName"
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          disabled={inviteClientState.status === "loading"}
        />
        <EmailTextField
          id="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={inviteClientState.status === "loading"}
        />
        <PhoneTextField
          id="phone"
          label="Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          disabled={inviteClientState.status === "loading"}
        />
      </FormFields>
    </FormModal>
  );
}
