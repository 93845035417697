import { createAsyncThunk } from "@reduxjs/toolkit";
import type { SelectableLocation } from "src/types/SelectableLocation";
import { getClientSummaries, resetClients } from "./slices/clientsSlice";
import {
  getCurrentUserLocations,
  resetLocations,
  selectLocation,
} from "./slices/locationsSlice";
import {
  getCurrentOrganization,
  resetOrganization,
} from "./slices/organizationSlice";
import { getCurrentUser, resetUser } from "./slices/userSlice";

export const resetAppData = createAsyncThunk(
  "app/resetAppData",
  async (_, { dispatch }) => {
    dispatch(resetUser());
    dispatch(resetOrganization());
    dispatch(resetLocations());
    dispatch(resetClients());
  },
);

export const refreshAppData = createAsyncThunk(
  "app/refreshAppData",
  async (_, { dispatch }) => {
    // These can run concurrently.
    await Promise.all([
      dispatch(getCurrentUser()),
      dispatch(getCurrentOrganization()),
    ]);
    // These must run serially, order is important.
    await dispatch(getCurrentUserLocations());
    await dispatch(getClientSummaries({ page: 1 }));
  },
);

export const selectLocationAndFetchClients = createAsyncThunk(
  "app/selectLocation",
  async (
    { selectedLocation }: { selectedLocation: SelectableLocation },
    { dispatch },
  ) => {
    dispatch(selectLocation(selectedLocation));
    await dispatch(
      getClientSummaries({
        page: 1,
        locationId:
          selectedLocation.type === "location"
            ? selectedLocation.location._id
            : undefined,
      }),
    );
  },
);
