import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Organization } from "@trainwell/types";
import { api } from "src/lib/trainwellApi";
import { NetworkingState } from "src/types/NetworkingState";

export const getCurrentOrganization = createAsyncThunk(
  "organization/getCurrentOrganization",
  async () => {
    const { organization } = await api.organizations.getCurrentOrganization();
    return organization;
  },
);

interface OrganizationSlice {
  organizationState: NetworkingState<Organization>;
}

// Define the initial state using that type
const initialState: OrganizationSlice = {
  organizationState: NetworkingState.idle(),
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    resetOrganization: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentOrganization.pending, (state) => {
      state.organizationState = NetworkingState.loading();
    });
    builder.addCase(getCurrentOrganization.fulfilled, (state, action) => {
      state.organizationState = NetworkingState.succeeded(action.payload);
    });
    builder.addCase(getCurrentOrganization.rejected, (state, action) => {
      state.organizationState = NetworkingState.failed(action.error.message);
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
