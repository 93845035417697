import { Outlet } from "react-router";
import AppProviders from "src/providers/AppProviders";

export default function AppRoot() {
  return (
    <AppProviders>
      <Outlet />
    </AppProviders>
  );
}
