import { type TextFieldProps } from "@mui/material";
import { Validator } from "src/lib/validators";
import TextField from "./index";

export const { validator: PhoneValidator, message: PhoneHelperMessage } =
  Validator.String.phone;

export function PhoneTextField({ value, ...rest }: TextFieldProps) {
  return (
    <TextField
      value={value}
      helperText={!PhoneValidator(value as string) ? PhoneHelperMessage : null}
      {...rest}
    />
  );
}
