import { Button, Typography } from "@mui/material";
import type { OrganizationUser } from "@trainwell/types";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import useShouldRefreshData from "src/hooks/useShouldRefreshData";
import { getUsers } from "src/redux/slices/userSlice";
import { Chip } from "../Shared/Chip";
import LocationChipStack from "../Shared/LocationChipStack";
import Table from "../Shared/Table";
import { TableState, type TableDelegateMethods } from "../Shared/Table/types";
import { columns, getTableState, type UserTableColumn } from "./utils";

export function UsersTable({
  onCreate,
  onEdit,
}: {
  onCreate: () => void;
  onEdit: (user: OrganizationUser) => void;
}) {
  const usersState = useAppSelector((state) => state.user.usersState);
  const dispatch = useAppDispatch();

  const shouldRefreshData = useShouldRefreshData();

  const refreshUsers = useCallback(
    (page?: number) => {
      dispatch(getUsers({ page: page }));
    },
    [dispatch],
  );

  const tableState: TableState<OrganizationUser> = useMemo(
    () =>
      getTableState(usersState, () =>
        refreshUsers(
          usersState.status === "succeeded" ? usersState.data.page : undefined,
        ),
      ),
    [usersState, refreshUsers],
  );

  const handleSelectPage = useCallback(
    (page: number) => refreshUsers(page),
    [refreshUsers],
  );

  const delegateMethods: TableDelegateMethods<
    OrganizationUser,
    UserTableColumn
  > = useMemo(
    () => ({
      keyForRow: (item) => item._id,
      renderCell: (item, column) => {
        switch (column.key) {
          case "first_name":
            return <Typography>{item.first_name}</Typography>;
          case "last_name":
            return <Typography>{item.last_name}</Typography>;
          case "email":
            return <Typography>{item.email}</Typography>;
          case "locations":
            return (
              <LocationChipStack
                locationIds={item.locations}
                role={item.auth.role}
              />
            );
          case "role":
            return (
              <Chip
                chipStyle={item.auth.role === "admin" ? "primary" : "neutral"}
              >
                {item.auth.role === "admin" ? "Admin" : "Member"}
              </Chip>
            );
        }
      },
      onClickRow: (item) => onEdit(item),
    }),
    [onEdit],
  );

  // Kick off a fetch request for users summary if needed.
  useEffect(() => {
    if (!shouldRefreshData(usersState)) return;
    refreshUsers();
  }, [usersState, shouldRefreshData, refreshUsers]);

  return (
    <Table
      headerConfig={{
        title: "Users",
        subtitle: "Manage the users in your organization",
        rightChildren: (
          <>
            <Button
              variant="contained"
              sx={{ fontSize: "16px", height: "40px", pl: "20px" }}
              startIcon={
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-0.0078125 7.35938C-0.0078125 7.03125 0.106771 6.7526 0.335938 6.52344C0.570312 6.28906 0.851562 6.17188 1.17969 6.17188H5.51562V1.84375C5.51562 1.51562 5.63021 1.23698 5.85938 1.00781C6.08854 0.773438 6.36719 0.65625 6.69531 0.65625C7.02344 0.65625 7.30208 0.773438 7.53125 1.00781C7.76562 1.23698 7.88281 1.51562 7.88281 1.84375V6.17188H12.2188C12.5417 6.17188 12.8177 6.28906 13.0469 6.52344C13.2812 6.7526 13.3984 7.03125 13.3984 7.35938C13.3984 7.6875 13.2812 7.96875 13.0469 8.20312C12.8177 8.43229 12.5417 8.54688 12.2188 8.54688H7.88281V12.8828C7.88281 13.2057 7.76562 13.4818 7.53125 13.7109C7.30208 13.9453 7.02344 14.0625 6.69531 14.0625C6.36719 14.0625 6.08854 13.9453 5.85938 13.7109C5.63021 13.4818 5.51562 13.2057 5.51562 12.8828V8.54688H1.17969C0.851562 8.54688 0.570312 8.43229 0.335938 8.20312C0.106771 7.96875 -0.0078125 7.6875 -0.0078125 7.35938Z"
                    fill="white"
                  />
                </svg>
              }
              onClick={onCreate}
            >
              Create
            </Button>
          </>
        ),
      }}
      footerConfig={{
        onSelectPage: handleSelectPage,
      }}
      columns={columns}
      tableState={tableState}
      delegateMethods={delegateMethods}
    />
  );
}
