import { Stack, type ButtonProps } from "@mui/material";
import React, { type FormEventHandler } from "react";
import type { NetworkingState } from "src/types/NetworkingState";
import { Modal } from "../Modal";
import FormActions from "./Actions";
import NetworkingStateMessage from "./NetworkingStateMessage";
import SubmitDoneButton from "./SubmitDismissButton";

export type FormModalProps<T> = {
  children: React.ReactNode;
  open: boolean;
  title: string;
  ctaTitle: string;
  successMessage: string;
  isFormValid: boolean;
  networkingState: NetworkingState<T>;
  bottomLeftButton?: React.ReactNode;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  submitButtonProps?: ButtonProps;
  onClose: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
};

export function FormModal<T>({
  children,
  open,
  title,
  ctaTitle,
  successMessage,
  isFormValid,
  networkingState,
  leftActions,
  rightActions,
  submitButtonProps,
  onClose,
  onSubmit,
}: FormModalProps<T>) {
  return (
    <Modal open={open} onClose={onClose} title={title}>
      <form onSubmit={onSubmit}>
        <Stack direction="column" gap="24px" sx={{ p: "24px 0px 0px 0px" }}>
          <NetworkingStateMessage
            networkingState={networkingState}
            successMessage={successMessage}
          />

          {children}

          <FormActions>
            {networkingState.status !== "succeeded" && leftActions}
            <SubmitDoneButton
              title={ctaTitle}
              isFormValid={isFormValid}
              networkingState={networkingState}
              onClickDone={onClose}
              {...submitButtonProps}
            />
            {networkingState.status !== "succeeded" && rightActions}
          </FormActions>
        </Stack>
      </form>
    </Modal>
  );
}
