import { Stack, Typography } from "@mui/material";
import type { OrganizationUser } from "@trainwell/types";
import { useAppSelector } from "src/hooks/stateHooks";
import { NetworkingState } from "src/types/NetworkingState";

function title(userState: NetworkingState<OrganizationUser>) {
  if (userState.status !== "succeeded") return " ";
  return `Welcome back, ${userState.data.first_name}`;
}

export function Greeting() {
  const currentUserState = useAppSelector(
    (state) => state.user.currentUserState,
  );

  return (
    <Stack
      direction="column"
      sx={{
        width: "100%",
        gap: "10px",
      }}
    >
      <Typography variant="h1" sx={{ minHeight: "36px" }}>
        {title(currentUserState)}
      </Typography>
      <Typography
        sx={{ color: "gray.500", fontSize: "16px", minHeight: "27px" }}
      >
        What would you like to do?
      </Typography>
    </Stack>
  );
}
