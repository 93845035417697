export const Validator = {
  String: {
    length: ({ min, max }: { min?: number; max?: number }) => ({
      validator: (value: string) => {
        const stringLength = value.trim().length;
        if (min && stringLength < min) return false;
        if (max && stringLength > max) return false;
        return true;
      },
      message:
        min && max
          ? `Must be between ${min} and ${max} characters`
          : min
            ? `Must be at least ${min} characters`
            : max
              ? `Must be less than ${max} characters`
              : "",
    }),
    email: {
      validator: (value: string) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(value);
      },
      message: "Must be a valid email.",
    },
    phone: {
      validator: (value: string) => {
        const regex = /^(\d{10}|\d{3}-\d{3}-\d{4})$/;
        return regex.test(value);
      },
      message:
        "“Must be a valid 10-digit US-based phone number: 212-456-7890 or 2124567890",
    },
  },
};
