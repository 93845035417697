import { Link, Stack, styled, Typography } from "@mui/material";
import type { OrgClientDetails, OrgClientSummary } from "@trainwell/types";
import type { ReactNode } from "react";

const ClientDetailContactSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Stack direction="column" gap="8px">
    <Typography
      sx={{
        fontSize: { xs: "14px", lineHeight: "20px" },
        fontWeight: 500,
        color: "gray.600",
      }}
    >
      {title}
    </Typography>
    {children}
  </Stack>
);
const StyledLink = styled(Link)(() => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
}));

export const ClientDetailContact = ({
  summary,
  details,
}: {
  summary: OrgClientSummary;
  details?: OrgClientDetails;
}) => (
  <Stack direction="column" gap="24px">
    {summary.phone_number && (
      <ClientDetailContactSection title="Phone">
        <StyledLink href={`tel: ${summary.phone_number}`}>
          {summary.phone_number}
        </StyledLink>
      </ClientDetailContactSection>
    )}

    {summary.email && (
      <ClientDetailContactSection title="Email">
        <StyledLink href={`mailto: ${summary.email}`}>
          {summary.email}
        </StyledLink>
      </ClientDetailContactSection>
    )}

    {details?.trainer && (
      <ClientDetailContactSection title="Trainer">
        <StyledLink>{`${details.trainer.first_name} ${details.trainer.last_name}`}</StyledLink>
        <StyledLink href={`tel: ${details.trainer.phone_number}`}>
          {details.trainer.phone_number}
        </StyledLink>
        <StyledLink href={`mailto: ${details.trainer.email}`}>
          {details.trainer.email}
        </StyledLink>
      </ClientDetailContactSection>
    )}
  </Stack>
);
