import { Typography } from "@mui/material";
import { type OrgClientSummary } from "@trainwell/types";
import { Chip } from "./Chip";

export const ClientStatusCellContent = ({
  summary,
}: {
  summary: OrgClientSummary;
}) => {
  const isActive = summary.state.startsWith("active_");
  return (
    <Chip chipStyle={isActive ? "success" : "neutral"}>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", height: "min-content" }}
      >
        {isActive ? "Active" : "Inactive"}
      </Typography>
    </Chip>
  );
};
