import { Typography } from "@mui/material";
import { type OrgClientSummary } from "@trainwell/types";

export const ClientMovementStreakCellContent = ({
  summary,
}: {
  summary: OrgClientSummary;
}) => (
  <Typography
    variant="body2"
    sx={{ fontSize: "14px", fontWeight: "300", height: "min-content" }}
  >
    {`🔥 ${summary.streak} days`}
  </Typography>
);
