import { Box, Link, Stack, Typography } from "@mui/material";

export default function RouteCard({
  title,
  subtitle,
  path,
  iconPath,
}: {
  title: string;
  subtitle: string;
  path: string;
  iconPath: string;
}) {
  return (
    <Link href={path}>
      <Stack
        direction="column"
        sx={{
          p: "24px",
          gap: "24px",
          border: "1px solid #EAECF0",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          borderRadius: "12px",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center", gap: "12px" }}>
          <Box
            component={"img"}
            src={iconPath}
            sx={{
              width: "48px",
              height: "48px",
              border: "1px solid #EAECF0",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              borderRadius: "10px",
            }}
          />
          <Typography variant="h6" sx={{ color: "gray.700" }}>
            {title}
          </Typography>
        </Stack>
        <Typography variant="caption" sx={{ color: "gray.500" }}>
          {subtitle}
        </Typography>
      </Stack>
    </Link>
  );
}
