import { Typography } from "@mui/material";
import { type OrgClientSummary } from "@trainwell/types";
import { getPercentageString } from "../../lib/utils";
import { Chip } from "./Chip";

const ClientConsistencyCellContent = ({
  count,
  total,
}: {
  count?: number;
  total?: number;
}) => {
  return (
    <Chip chipStyle={"primary"}>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", height: "min-content" }}
      >
        {count && total
          ? `${count}/${total} (${getPercentageString(count, total)})`
          : "N/A"}
      </Typography>
    </Chip>
  );
};

export const ClientWorkoutConsistencyCellContent = ({
  summary,
}: {
  summary: OrgClientSummary;
}) => (
  <ClientConsistencyCellContent
    count={summary.workout_consistency.n_workouts_completed}
    total={summary.workout_consistency.n_workouts_prescribed}
  />
);

export const ClientNutritionConsistencyCellContent = ({
  summary,
}: {
  summary: OrgClientSummary;
}) => (
  <ClientConsistencyCellContent
    count={summary.nutrition_consistency?.n_days_logged}
    total={summary.nutrition_consistency?.n_possible_days_logged}
  />
);
