import { type TextFieldProps } from "@mui/material";
import { Validator } from "src/lib/validators";
import TextField from "./index";

export const { validator: NameValidator, message: NameHelperMessage } =
  Validator.String.length({ min: 1, max: 52 });

export function NameTextField({ value, ...rest }: TextFieldProps) {
  return (
    <TextField
      value={value}
      helperText={!NameValidator(value as string) ? NameHelperMessage : null}
      {...rest}
    />
  );
}
