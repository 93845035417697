import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { DEFAULT_BORDER } from "../defaults";
import { type FailedTableState, type TableColumn } from "../types";

export default function FailedBodyContent({
  columns,
  message,
  onTryAgain,
}: { columns: TableColumn[] } & FailedTableState) {
  return (
    <TableRow
      key={"error.row"}
      sx={{
        height: "72px",
      }}
    >
      <TableCell
        colSpan={columns.length}
        key={"error.cell"}
        sx={{
          bgcolor: "error.50",
          borderTop: DEFAULT_BORDER,
          borderBottom: DEFAULT_BORDER,
        }}
      >
        <Stack
          direction="column"
          gap="10px"
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "gray.500" }}>{message}</Typography>
          {onTryAgain && (
            <Button variant="text" onClick={onTryAgain}>
              Try Again
            </Button>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
