import type { OrganizationUser } from "@trainwell/types";
import { useState, type FormEventHandler } from "react";
import DeleteIconButton from "src/components/Shared/Buttons/DeleteIconButton";
import MultiSelectLocations from "src/components/Shared/Fields/Select/MultiSelectLocation";
import { RoleSelect } from "src/components/Shared/Fields/Select/Role";
import { EmailTextField } from "src/components/Shared/Fields/Text/Email";
import { NameTextField } from "src/components/Shared/Fields/Text/Name";
import { ConfirmDeleteModal } from "src/components/Shared/Modals/ConfirmDeleteModal";
import { FormModal } from "src/components/Shared/Modals/Form";
import FormFields from "src/components/Shared/Modals/Form/Fields";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  deleteUser,
  resetDeleteUserState,
  resetUpdateUserState,
  updateUser,
} from "src/redux/slices/userSlice";
import useUserForm from "./useUserForm";

export default function ManageUserModal({
  user,
  onClose,
}: {
  user?: OrganizationUser;
  onClose: () => void;
}) {
  const locationsState = useAppSelector((s) => s.locations.locationsState);
  const updateUserState = useAppSelector((s) => s.user.updateUserState);
  const deleteUserState = useAppSelector((s) => s.user.deleteUserState);
  const dispatch = useAppDispatch();

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    locationIds,
    setLocationIds,
    role,
    setRole,
    isFormValid,
  } = useUserForm(user);

  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!user) return;

    const processedLocationIds =
      role === "admin" && locationsState.status === "succeeded"
        ? locationsState.data.map((l) => l._id)
        : locationIds;

    dispatch(
      updateUser({
        userId: user._id,
        updates: {
          first_name: firstName,
          last_name: lastName,
          locations: processedLocationIds,
          auth: { role: role },
        },
      }),
    );
  };

  const handleDeleteUser: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!user) return;
    dispatch(deleteUser({ userId: user._id }));
  };

  const handleClose = () => {
    onClose();
    dispatch(resetUpdateUserState());
    dispatch(resetDeleteUserState());
  };

  const handleCloseFromDeletion = () => {
    setIsConfirmingDelete(false);
    handleClose();
  };

  return (
    <FormModal
      open={!!user}
      title="Manage User"
      ctaTitle="Edit"
      successMessage="User edited successfully."
      isFormValid={isFormValid}
      networkingState={updateUserState}
      leftActions={
        <DeleteIconButton onClick={() => setIsConfirmingDelete(true)} />
      }
      onClose={handleClose}
      onSubmit={handleSubmit}
    >
      <FormFields networkingState={updateUserState}>
        <EmailTextField
          id="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled
        />
        <NameTextField
          id="firstName"
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          disabled={updateUserState.status === "loading"}
        />
        <NameTextField
          id="lastName"
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          disabled={updateUserState.status === "loading"}
        />
        <RoleSelect
          id="role"
          value={role}
          onChange={(e) =>
            setRole(e.target.value as OrganizationUser["auth"]["role"])
          }
          required
          disabled={updateUserState.status === "loading"}
        />
        {role === "member" && (
          <MultiSelectLocations
            id="Locations"
            label="Locations"
            value={locationIds}
            onChange={(e) => setLocationIds(e.target.value as string[])}
            disabled={updateUserState.status === "loading"}
          />
        )}
      </FormFields>
      <ConfirmDeleteModal
        open={isConfirmingDelete}
        title={`Delete ${user?.first_name ?? "User"}?`}
        successMessage={`${user?.first_name ?? "User"} deleted.`}
        networkingState={deleteUserState}
        onSubmit={handleDeleteUser}
        onClose={handleCloseFromDeletion}
      />
    </FormModal>
  );
}
