import { Typography } from "@mui/material";
import { NetworkingState } from "src/types/NetworkingState";

export default function NetworkingStateMessage<T>({
  networkingState,
  successMessage = "Request completed successfully.",
}: {
  networkingState: NetworkingState<T>;
  successMessage?: string;
}) {
  switch (networkingState.status) {
    case "succeeded":
      return (
        <Typography
          sx={{
            p: "20px",
            color: "success.500",
            bgcolor: "success.50",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          {successMessage}
        </Typography>
      );
    case "failed":
      return (
        <Typography
          sx={{
            p: "20px",
            color: "error.500",
            bgcolor: "error.50",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          {networkingState.error}
        </Typography>
      );
    default:
      return null;
  }
}
