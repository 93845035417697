import { useCallback, useEffect, useMemo, useState } from "react";
import { EmailValidator } from "src/components/Shared/Fields/Text/Email";
import { NameValidator } from "src/components/Shared/Fields/Text/Name";
import { PhoneValidator } from "src/components/Shared/Fields/Text/Phone";

export default function useInviteClientForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const isFormValid = useMemo(() => {
    const isInvalid = [
      NameValidator(firstName),
      NameValidator(lastName),
      EmailValidator(email),
      PhoneValidator(phone),
    ].includes(false);
    return !isInvalid;
  }, [firstName, lastName, email, phone]);

  const resetForm = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
  }, []);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    isFormValid,
    resetForm,
  };
}
