import { Button, Typography } from "@mui/material";
import { Modal } from "../Shared/Modals/Modal";
import { TablePageContainer } from "../Shared/Table/PageContainer";

export function NotFound() {
  return (
    <TablePageContainer sx={{ mt: "64px", mb: "64px" }}>
      <Modal open={true} title="Page not found">
        <Typography sx={{ textAlign: "center", mt: "32px", mb: "32px" }}>
          This page has either moved or been deleted.
        </Typography>
        <Button href="/" fullWidth sx={{ height: "40px" }}>
          Go Home
        </Button>
      </Modal>
    </TablePageContainer>
  );
}
