import { TextField, type TextFieldProps } from "@mui/material";

export default function CustomTextField({ ...rest }: TextFieldProps) {
  return (
    <TextField
      margin="dense"
      type="text"
      size="small"
      fullWidth
      variant="outlined"
      {...rest}
    />
  );
}
